// Here you can add other styles
#DateRangePickerChildren{
    display: flex;
    @media only screen and (max-width: 600px) {
        display: inherit !important;
    }

}

.share-icon{
    cursor : pointer;
    background: #20a8d8;
    text-align:center;
    border-radius: 50%;
    display: inline-block;
    font-size:16px ;
    margin: 0px 2px;
    // padding: 3px;
    width: 32px;
    height:32px;
    color: #ffff ; 
}